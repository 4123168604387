/*@import url(https://fonts.googleapis.com/css?family=Times:400,600,700);*/
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  background: #f5f3f3;
  color: #000000;
  font-family: "Times", serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4 {
  margin: 0 0 14.66667px; }

a {
  color: #0090ff;
  text-decoration: none; }

nav {
  background: #000000;
  height: 44px;
  text-align: center;
  padding: 4px; }
  nav a {
    border: 1px solid #004880;
    background: #000000;
    color: #0090ff;
    padding: 0 8.8px;
    border-left: none;
    height: 36px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 36px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block; }
    nav a:focus {
      outline: none; }
    nav a:first-child {
      border-left: 1px solid #004880; }
    nav a:active, nav a:target {
      background: #0090ff;
      color: #f5f3f3; }

.layout--wrapper {
  height: calc(100vh - 44px);
  overflow-y: scroll; }

.layout {
  padding: 14.66667px; }
  .layout.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch; }
    .layout.flex .card {
      width: 16.666667%;
      height: 420px;
      margin-bottom: 0; }
      .layout.flex .card p {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      @media screen and (max-width: 1419px) {
        .layout.flex .card {
          width: 20%; } }
      @media screen and (max-width: 1023px) {
        .layout.flex .card {
          width: 25%; } }
      @media screen and (max-width: 727px) {
        .layout.flex .card {
          width: 33.33334%; } }
      @media screen and (max-width: 600px) {
        .layout.flex .card {
          width: 50%; } }
  .layout.masonry {
    column-count: 5;
    column-gap: 0.8px; }
    .layout.masonry .card {
      break-inside: avoid;
      page-break-inside: avoid; }
    .layout.masonry .card:nth-child(6n+1) {
      clear: left; }
  .layout.single-column {
    max-width: 440px;
    margin: 0 auto; }
  .layout.two-column {
    max-width: 800px;
    margin: 0 auto;
    column-count: 2;
    column-gap: 0.8px; }
    .layout.two-column .card {
      break-inside: avoid;
      page-break-inside: avoid; }

.info {
  text-align: center;
  padding: 11px; }
  .info > * {
    margin: 8px 0 0; }
  .info h2 {
    line-height: 32px; }
  .info span {
    background: #fff;
    border: #f5f3f3 solid 1px;
    margin-right: 1px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    display: inline-block; }

.card {
  position: relative;
  border: 4px solid #f5f3f3;
  background: #fff;
  min-height: 288px;
  width: 100%;
  margin-bottom: 0.8px;
  color: #1a1a1a;
  break-inside: avoid;
  page-break-inside: avoid; }
  .card > div {
    padding: 22px; }
  .card .media {
    position: relative;
    min-height: 200px;
    height: 15vh;
    overflow: hidden;
    padding: 0;
    background: rgba(51, 102, 153, 0.15);
    border: 6px solid #fff; }
    .card .media img {
      filter: grayscale(100%);
      width: 100%; }
  .card h2, .card h3, .card h4, .card p {
    font-family: "serif"; }
  .card.active {
    border-color: #0090ff;
    color: #0090ff; }
